import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line

import SEO from "../components/SEO"
import AnimationRevealPage from "./helpers/AnimationRevealPage.js"
import { Container, ContentWithPaddingXl } from "./treact/misc/Layouts"
import Footer from "./treact/footers/MiniCenteredFooter.js"
import Header from "./treact/headers/light"

import { SectionHeading } from "./treact/misc/Headings"
import Logo from '../images/mf-icon.png';

const HeaderContainer = tw.div`-mt-2`
const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-beige-900 mb-10`
const Text = styled.div`
  ${tw`text-lg  text-beige-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`

export const StaticLayout = ({ headingText, children, className }) => (
  <AnimationRevealPage>
    <HeaderContainer>
      <Header />
      <SEO
        title={headingText}
        image={Logo}
        keywords={[
          { headingText },
          `mint`,
          `fox`,
          `studio`,
          `kniting`,
          `handmade`,
          `knited lingerie`,
          `knited underware`,
          `knited swimsuite`,
          `accesories`,
          `Slava Kozachok`,
          `mintfox`,
        ]}
      />
    </HeaderContainer>
    <Container>
      <ContentWithPaddingXl>
        <HeadingRow>
          <Heading>{headingText}</Heading>
        </HeadingRow>
        <Text className={className}>{children}</Text>
      </ContentWithPaddingXl>
    </Container>

    <Footer />
  </AnimationRevealPage>
)
StaticLayout.propTypes = {
  headingText: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}
